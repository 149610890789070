/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:b4a1ec14-d76a-4717-a0fc-171c17598d43",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_8QdXhfjmy",
    "aws_user_pools_web_client_id": "1m2js2beec8k831aggk9abu0v2",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "whitepapermanager219b3b730295f4fedb0161d5ec0287172052-staging",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "wpdownloads-staging",
            "region": "us-east-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "lpwpdl",
            "endpoint": "https://s9968xn3w8.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;

import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';

import Link from '@mui/material/Link';




function Header() {

  return (
    <React.Fragment>
      <Toolbar sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: '#282c34 ', mb:2}}>
        <Link  target="_blank" href="https://leadingpath.com/"><img 
              src={require('./LP-Logo-No-Consult.svg')}
              alt="Leading Path Logo"
            /></Link>
        
      </Toolbar>
      
    </React.Fragment>
  );
}



export default Header;
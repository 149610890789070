import * as React from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Divider, FormHelperText, Grid } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Storage } from 'aws-amplify';
import getExpiry from './ExpiryTime';

export default function RegisterForm(props) {
  const getWhitePaperUrl = async () => {
    //60 second link...
    const fileAccessURL = await Storage.get(props.wpMetaData.name, {
      expires: 60,
    });
    props.setFileUrl(fileAccessURL);
  };

  const axiosInstance = axios.create({
    headers: { 'x-api-key': process.env.REACT_APP_X },
  });

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const signUpFormSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('First name is required')
      .min(2, 'Name is a minimum of 2 characters'),
    lastName: Yup.string()
      .required('Last name is required')
      .min(2, 'Name is a minimum of 2 characters'),
    email: Yup.string().email().required('Email is required'),
    phone: Yup.string()
      .required('Phone Number is required')
      .matches(phoneRegExp, 'Phone number is not valid')
      .min(10, 'Phone number is too short')
      .max(10, 'Phone number is too long'),
    title: Yup.string().required('Title is required'),
    companyName: Yup.string().required('Company name is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      title: '',
      companyName: '',
    },
    validationSchema: signUpFormSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {

      try {
        await getWhitePaperUrl();
      } catch (error) {
        setErrors({ submit: error });
      }

      const now = new Date();

      const personData = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        title: values.title,
        companyName: values.companyName,
      };

      const reqBody = {
        y: values.email,
        tstamp: now.toISOString(),
        wref: props.wpMetaData.name,
        personData: personData,
        expTime : getExpiry()
      };

      axiosInstance
        .post(process.env.REACT_APP_URL, reqBody)
        .then((response) => {
          console.log(`response from call ${response}`);
        })
        .catch((error) => {
          setErrors({ submit: error });
        });

      setTimeout(() => {
        setSubmitting(false);
      }, 400);
    },
  });

  return (
  
      <form onSubmit={formik.handleSubmit}>
      <Grid container >
        <Grid item xs={12}>
        <TextField 
          fullWidth
          size="small"
          margin="normal" 
          id="firstName"
          name="firstName"
          label="First Name"
          required
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
        </Grid>
        <Grid item xs={12}>
        <TextField 
          fullWidth 
          size="small"  
          margin="normal"
          id="lastName"
          name="lastName"
          label="Last Name"
          required
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
        </Grid>
        <Grid item xs={12}>
        <TextField 
          fullWidth   
          size="small"
          margin="normal"
          id="email"
          name="email"
          type="email"
          label="Email Address"
          required
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        </Grid>
        <Grid item xs={12}>
          <TextField 
            fullWidth
            size="small"
            margin="normal"
            id="phone"
            name="phone"
            label="Business Phone"
            required
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            size="small"
            id="title"
            name="title"
            label="Title"
            required
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField 
            fullWidth
            size="small"
            margin="normal"    
            id="companyName"
            name="companyName"
            label="Company Name"
            required
            value={formik.values.companyName}
            onChange={formik.handleChange}
            error={
              formik.touched.companyName && Boolean(formik.errors.companyName)
            }
            helperText={formik.touched.companyName && formik.errors.companyName}
          />
        </Grid>
        <Divider/>
        <Grid item xs={12}>
        {formik.errors.submit && (
          <FormHelperText error>{formik.errors.submit}</FormHelperText>
        )}
        </Grid>
        <Grid item xs={12} alignItems="center">
        <Button
          variant="contained"
          type="submit"
          disabled={!formik.dirty || formik.isSubmitting}
        >
          Submit
        </Button>
        </Grid>

      </Grid>

      </form>
  );
}

const getExpiry = () => {
  const now = new Date().valueOf();

  const offset = Number.parseFloat(process.env.REACT_APP_EXPIRY_OFFSET);

  return now + offset;
};

console.log(getExpiry());

module.exports = getExpiry;

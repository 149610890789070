import * as React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';


import { Amplify } from 'aws-amplify';
import { Auth } from '@aws-amplify/auth';

import RegisterForm from './RegisterForm';
import Header from './components/header';
import Footer from './components/footer';

import config from './aws-exports';
import wpData from './wp_data.json';
import './App.css';

Amplify.configure(config);
Auth.configure(config);

const getWhitepaperMetaData = (id) => {
  return wpData.find((wpDataItem) => {
    return wpDataItem.id === Number.parseInt(id);
  });

};

function App() {
  const [wpMetaData, setWpMetaData] = useState(0);
  const [fileUrl, setFileUrl] = useState('');

  //Get the request paramater, wp
  const search = useLocation().search;
  const inputWpId = new URLSearchParams(search).get('wp');

  //Upon load
  useEffect(() => {
    setWpMetaData(getWhitepaperMetaData(inputWpId));
  }, []);

  const theme = createTheme();

  //Not using <p>Category {wpMetaData.category}</p>
  return (
    <ThemeProvider theme={theme}>
            <CssBaseline />

      <Container maxWidth="large" >
        <Header gutterBottom sx={{mb:2}}/>
        <Grid container spacing={2}> 
          <Grid item lg={7} xs={12}>
            <Card>
              {wpMetaData ? (
                <CardMedia component="img" height="400px"
                image={require(`${wpMetaData.image}`)}
                alt="Whitepaper source image"
             />) : (
                  ''
                )} 
              <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                  {wpMetaData.title}
                  </Typography>
                  <Stack spacing={2}>
                      <Typography variant="body1" color="text.secondary">
                        {wpMetaData.body} 
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        {wpMetaData.body2}  
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        {wpMetaData.body3}
                      </Typography>
                  </Stack>
              </CardContent>
              
            </Card>
          </Grid>
          <Grid item lg={5} xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div" color="black" gutterBottom>
                REGISTER TO DOWNLOAD
                </Typography>
                <RegisterForm wpMetaData={wpMetaData} setFileUrl={setFileUrl}/>
              </CardContent>
              
            <CardActions>
              {fileUrl ? <Button size="small" href={fileUrl}>Download Whitepaper</Button> : ''}
            </CardActions>
            </Card>
          </Grid>
          
        </Grid>
      </Container>
      <Footer title="Leading Path White Papers" description={wpMetaData.title} />
    </ThemeProvider>
    
  );
}

export default App;
